.contact-form {
    margin: 40px auto;
    width: 30%;
    height: 50%;
    direction: ltr;
}

.contact-div {
    text-align: center;
    margin-top: 40px;
    direction: rtl;
}

@media screen and (max-width: 800px) {
    .contact-form {
        width: 70%;
        height: 50%;
    }
}