.body {
    background-color: white,
}

.paper {
    margin-top: 100px;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
}
  
.avatar {
    margin: 0 auto;
    background-color: #f50057;
}

.sign {
    text-align: center;
    line-height: 1.33;
    letter-spacing: 0em;
}

.form {
    width: '100%';
    margin-top: 10px;
}
  
.submit {
    margin: 30px 30px;
}