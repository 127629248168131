.info-card {
    margin: 30px auto;
}

.info-card-icon {
    transform: rotate(0deg);
    margin-left: auto;
    transition: transform;
}

.info-card-icon-open {
    transform: rotate(180deg);
}

.info-card-icon:focus {
    outline: white;
}

.info-card-icon-open:focus {
    outline: white;
}
