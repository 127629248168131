.card2 {
    margin: 40px 20px;
    display: inline-block;
}

.card-header2 {
    padding: 0.3rem 0.75rem;
}

.card-body2 {
    padding: 0.75rem 0.75rem;
}

.clear-filters {
    background-image: url(../../assets/clear-filters.svg);
    height: 24px;
    float: left;
}