.content-mobile {
    direction: rtl;
    line-height: 1.6;
    text-align: right;
}

.table {
    width: 100%;
}

.main2 {
    margin: 0px 20px;
    text-align: right;
}

.content-desktop {
    margin-top: 70px;
    display: flex;
    direction: rtl;
}

.tabs {
    border-left: 1px solid;
    margin-left: 3%;
    height: 240px;
}

.tab-item:focus {
    outline: white;
}

.MuiTab-wrapper {
    font-size: 15px;
}

.text2 {
    max-width: 70%;
}

.MuiTypography-body1 {
    font-size: 14px;
}