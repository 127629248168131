.toolbar {
    top: 0;
    display: float;
    float: right;
    background-color: lightblue;
    color: black;
}

.menu-button:focus {
    outline: lightblue;
}

.menu-div {
    display: flex;
    float: right;
    margin-left: auto;
}

.menu {
    direction: rtl;
    text-align: right;
}

.buttons {
    display: flex;
    float: left;
}

.contact {
    background-image: url(../../assets/email.svg);
    display: flex;
    float: left;
    height: 24px;
    width: 24px;
    background-size: 100% 100%;
    align-self: center;
}

.contact:focus {
    outline: lightblue;
}