.btn-primary {
    color: black;
    background-color: white;
    border-color: lightblue;
    font-size: 13px;
}

.btn-secondary {
    color: black;
    background-color: azure;
    border-color: lightblue;
    font-size: 13px;
    font-weight: bold;
}

.item {
    font-size: 13px;
    text-align: center;
}

.search {
    font-size: 13px; 
    text-align: center;
    width: 100%;
    direction: rtl;
}

