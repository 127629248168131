.bar-container {
    width: 60%;
    height: 520px;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-right: 64px;
    direction: ltr;
}

@media screen and (max-width: 800px) {
    .bar-container {
        width: 90%;
        height: 320px;
        margin-right: 44px;
    }
}

.pie-container {
    width: 60%;
    height: 520px;
    display: inline-block;
    margin-bottom: 40px;
    direction: ltr;
}

@media screen and (max-width: 800px) {
    .pie-container {
        width: 80%;
        height: 320px;
    }
}