.home-text {
    margin: 20px;
    direction: rtl;
    text-align: center;
}

.home-info {
    margin-top: 40px;
}

.home-image {
    background-image: url(../../assets/betterment.jpg);
    width: 40%;
    height: 400px;
    margin: 60px auto;
    background-size: 100% 100%;
}

@media screen and (max-width: 800px) {
    .home-image {
        width: 80%;
        height: 200px;
    }
}